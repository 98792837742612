import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import UserAPI from "../../api/User/UserAPI";
import UserContext from '../../singletons/userContext.js';
import { Container } from "react-bootstrap";
import { NavLink} from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightFromBracket, faList, faLock } from "@fortawesome/free-solid-svg-icons";
import UserData from "./UserData";
import ResetPassword from "../../pages/Public/ResetPassword/ResetPassword";

const UserProfile = () => {

	let { slug } = useParams();
	const [isLoggedIn, user, setUser] = useContext(UserContext);

	const [userData, setUserData] = useState({});

	slug = slug ? slug : 'profile';

	const slugs = {
		'profile': <UserData user={userData} />,
		'change_password': <ResetPassword isLogged = {true}/>,
	}

	const handleLogout = async () => {

		const response = await UserAPI.logout();
		if (response?.environment?.status?.id == 204) {
			localStorage.removeItem('user');
			setUser(null);
		}
	}

	const getProfile = async () => {
		const response = await UserAPI.getProfile();
		if (response?.environment?.status?.id == 200) {
			setUserData(response.data);
		}
	}

	useEffect(() => {
		getProfile();
	}, []);
	const [showMenu, setShowMenu] = useState(false);

	return (
		<Container fluid className="px-0">
			<div className="d-flex justify-content-between course_dash">
				<div className={"sidebar_course shadow_black profile_sidebar" + (showMenu ? ' sidebar_width' : '')}>
				<div className="sidebar_pos">
				<div className="ups_sidebar">
				<button onClick={() => setShowMenu(!showMenu)} className="btn_open_menu"></button>
				</div>
				<div className="body_sidebar">
					<div className="user_links">
						<NavLink  to={'/profile'} className={"mb-2 ic_lesson d-flex align-items-center " + (slug == 'profile' ? 'active_link ' : null)} ><FontAwesomeIcon icon={faList} className="me-2 link_icon" /><div className="lesson_title"> Datele tale</div></NavLink>
						<NavLink to={'/profile/change_password'} className={"ic_lesson d-flex align-items-center " + (slug == 'change_password' ? 'active_link' : null)}><FontAwesomeIcon icon={faLock} className="me-2 link_icon" /><div className="lesson_title">Schimbă parola</div></NavLink>
					</div>
					<button onClick={() => handleLogout()} type="submit" className="button_link btn_submit log_out_btn  bg_orange submit_btn d-flex ic_lesson justify-content-center log_out bold mt-4">
						<FontAwesomeIcon icon={faArrowRightFromBracket} className="mt-1 link_icon" /><div className="lesson_title ms-2">Logout</div></button>
				</div>
				</div>
				</div>
				<Container fluid className="px-0 container_lesson">
					<Container>
						{slugs[slug] ? slugs[slug] : <UserData user={userData} />}
					</Container>
				</Container>

			</div>
		</Container>

	);
};

export default UserProfile;