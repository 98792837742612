import { useContext } from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import UserContext from '../singletons/userContext';

function ProtectedRoute() {
  const [isLoggedIn, user, setUser] = useContext(UserContext);  


  return (
    isLoggedIn ? <Outlet /> : <Navigate to='/login' />
  );
}

export default ProtectedRoute;