import React, { useState, useRef, useEffect } from 'react';
import CourseAPI from '../../api/Course/CourseAPI';
import VimeoPlayer from './VimeoPlayer';

const VideoScreen = (props) => {
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [isRecording, setIsRecording] = useState(false);
  const [isFinishedVideo, setIsFinishedVideo] = useState(false);
  const videoRef = useRef(null);
  const isFinishedVideoRef = useRef(false);
  const mediaStreamRef = useRef(null);

  useEffect(() => {
    if (isFinishedVideo) {
      handleRecordingStop();
    }
  }, [isFinishedVideo]);

  const startRecording = async () => {
    try {
      const mediaStream = await navigator.mediaDevices.getUserMedia({ audio: true, video: true });
      mediaStreamRef.current = mediaStream;
      startNewRecorder(mediaStream);

      videoRef.current.srcObject = mediaStream;
      videoRef.current.play();
    } catch (error) {
    }
  };

  const startNewRecorder = (mediaStream) => {
    const recorder = new MediaRecorder(mediaStream, { mimeType: 'video/webm' });

    recorder.addEventListener('dataavailable', handleDataAvailable);
    recorder.addEventListener('stop', handleRecordingStop);

    setMediaRecorder(recorder);
    setIsRecording(true);
    recorder.start();

    setTimeout(() => {
      recorder.stop();
      if (!isFinishedVideoRef.current) {  // Use the ref here
          setTimeout(() => startNewRecorder(mediaStream), 100);
      } else {
      }
  }, 5000);
  
  };

  const handleDataAvailable = (event) => {
    const blob = event.data;
    if (blob && blob.size > 0 && !isFinishedVideo) {
      sendBlobToServer(blob);
    }
  };

  const handleRecordingStop = () => {
    if (mediaRecorder) {
      mediaRecorder.removeEventListener('dataavailable', handleDataAvailable);
      if (isRecording) {
        mediaRecorder.stop();
        setIsRecording(false);
      }
    }
  };

  const sendBlobToServer = (blob) => {
    const timestamp = new Date().toISOString().replace(/[^a-zA-Z0-9]/g, '');
    const filename = `recording_${timestamp}.webm`;

    const formData = new FormData();
    formData.append('recordedBlob', blob, filename);
    formData.append('user2lesson_id', props.user2lesson_id);

    CourseAPI.uploadBlob(formData);
  };

  const handleVideoEnd = () => {
    setIsFinishedVideo(true);
    isFinishedVideoRef.current = true; // Update the ref as well
    props.onEnd();
};

  return (
    <div>
      <video ref={videoRef} style={{ width: '100%', maxWidth: '500px', display: 'none', marginTop: '60px' }} />
      {!isRecording && <p className={'text-center'} style={{fontWeight:"700"}} >
          Când te simți pregătit să începi cursul, apasă pe butonul de mai jos. Cursul se va înregistra automat.
        </p>}
      <button className='button_link text-center d-flex mx-auto bg_purple' onClick={startRecording} disabled={isRecording}>

        {isRecording ? 'Cursul se inregistreaza!' : 'Începe cursul'}
      </button>

      {isRecording && <VimeoPlayer videoId={props.videoId} onEnd={handleVideoEnd} />}
    </div>
  );
};

export default VideoScreen;
