import React, { useState } from 'react';
import UserContext from './userContext';

const UserProvider = ({ children }) => {
    const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')));
    const is_authenticated = user?.token !== undefined;

    return (
        <UserContext.Provider value={[is_authenticated, user, setUser]}>
            {children}
        </UserContext.Provider>
    );
};

export default UserProvider;