import React, { useState, useEffect } from "react";

import { Accordion, Container } from "react-bootstrap";
import ProgressCourse from "../../../components/ProgressCourse/ProgressCourse";
import { BrowserRouter, NavLink, Route, Routes } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRight,
  faCheck,
  faCircleInfo,
  faCircleQuestion,
  faFilePdf,
  faGraduationCap,
  faLightbulb,
  faLock,
  faVideo,
} from "@fortawesome/free-solid-svg-icons";

import StaticLesson from "../../../components/Lessons/StaticLesson";
import VideoLesson from "../../../components/Lessons/VideoLesson";
import FormLesson from "../../../components/Lessons/FormLesson";

import CourseAPI from "../../../api/Course/CourseAPI";
import MessageSuccess from "../../../components/MessageSuccess/MessageSuccess";
import FinalCourse from "../Course/FinalCourse";
const CourseDashboard = (props) => {

  const iconMap = {
    faCircleInfo: faCircleInfo,
    faCircleQuestion: faCircleQuestion,
    faFilePdf: faFilePdf,
    faGraduationCap: faGraduationCap,
    faLightbulb: faLightbulb,
    faVideo: faVideo
  };

  const chapter_id = props?.chapter_id;
  const lesson_id = props?.lesson_id;
  const course = props.course;
  const chapters = props?.course?.chapters;

  const lesson = props?.course?.current_lesson;
  const final_course = props?.lesson?.final_course;

  const [lessonType, setLessonType] = useState('');
  useEffect(() => {
    setLessonType(lesson?.type);

  }), [lesson_id];

  const [showMenu, setShowMenu] = useState(localStorage.getItem('showMenu') === 'true' || false);
  const [finishedVideo, setFinishedVideo] = useState(false);
  const [finishedLesson, setFinishedLesson] = useState(lesson?.status == 'completed');
  const [finishedCourse, setFinishedCourse] = useState(course?.progress == 100);
  const [message, setMessage] = useState("");


  const setShowMenuLocalStorage = function (currentState) {
    console.log("bbb");
    localStorage.setItem('showMenu', currentState);
    setShowMenu(currentState);
  };


  const nextLessonRedirect = async () => {

    if(((lesson.type == 'quiz' || lesson.type == 'final') && lesson?.grade < 80) || lesson.type == 'text' || lesson.type  == 'video' || lesson.type == 'form'){
    if(lesson.status == 'completed' && !lesson?.final_lesson){
      window.location.href = '/course/' + course?.id + '/' + lesson?.next_chapter + '/' + lesson?.next_lesson;
    }
    else if(lesson.status == 'completed' && lesson?.final_lesson){
      window.location.href = '/course/' + course?.id + '/final/final' ;
    }
    }

    const body = {
      course: course?.id,
      current_lesson: lesson?.id,
      next_lesson: lesson?.next_lesson,
      final_lesson: lesson?.final_lesson,
    }


    const response = await CourseAPI.nextLesson(body);
    if (response?.environment?.status?.id == 200) {


      if (!lesson?.final_lesson) {
        window.location.href = '/course/' + course?.id + '/' + lesson?.next_chapter + '/' + lesson?.next_lesson;
      } 
      else if (lesson?.final_lesson){
        window.location.href = '/course/' + course?.id + '/final/final' ;
      }
      setMessage(response?.data?.message);
    }
    
  }

  const onFinished = () => {
    setFinishedVideo(true)
  }

  const onFinishedLesson = () => {
    setFinishedLesson(true)
  }

  return (
    <Container fluid className="px-0">
      <div className="d-flex justify-content-between course_dash">
        <div className={"sidebar_course shadow_black" + (showMenu == true ? ' sidebar_width' : '')}>
          <div className="sidebar_pos">
            <div className="ups_sidebar">
              <button onClick={() => setShowMenuLocalStorage(!showMenu)} className="btn_open_menu"></button>
              <h5 className="bold text-center">{course.title}</h5>
              <div className="mt-2 mt-sm-4 progress_wrap">
                <ProgressCourse percentage={course.progress} />
              </div>
            </div>
            <div className="body_sidebar">
              <Accordion defaultActiveKey={chapter_id} alwaysOpen>
                {chapters && chapters.map((chapter, key) => {
                 
                  return (
                    <Accordion.Item eventKey={chapter.id} >
                      <Accordion.Header >
                        <div className="chapter_resp">C{key + 1}</div>
                        <span className={"me-2 " + (chapter?.completed ? ' item-completed' : '')}>{chapter.rating + ". " + chapter.title}</span>
                      </Accordion.Header>
                      <Accordion.Body>
                        {chapter?.lessons && chapter.lessons.map((lesson) => {

                          if (lesson?.is_blocked == 1) {
                            return <div className="d-flex align-items-center justify-content-between item-disabled ">
                              <div className="ic_lesson d-flex align-items-center">
                                <FontAwesomeIcon
                                  icon={iconMap[lesson?.icon]}
                                  className="link_icon"
                                />
                                <div className="lesson_title" >{lesson.title}</div>
                              </div>
                              <FontAwesomeIcon className="ms-2" icon={faLock} />
                            </div>
                          }
                          return (
                            <NavLink to={'/course/' + course?.id + '/' + chapter.id + '/' + lesson.id} className={`d-flex align-items-center justify-content-between my-1 ${lesson?.status == 'completed' ? 'item-completed' : 'item-progress'}`}>
                              <div className="ic_lesson d-flex align-items-center">
                                <FontAwesomeIcon
                                  icon={iconMap[lesson?.icon]}
                                  className="link_icon"
                                />
                                <div className="lesson_title">{lesson.title}</div>
                              </div>
                              <FontAwesomeIcon icon={faCheck} />
                            </NavLink>
                          );
                        })}
                      </Accordion.Body>
                    </Accordion.Item>
                  );
                })}
              </Accordion>
              {course.progress==100 && <>
                <Accordion.Item>
                  <Accordion.Header >
                  <NavLink to={'/course/' + course?.id + '/final/final'} className='d-flex align-items-center justify-content-between my-1 ev_info final_link '>
                  <div className="ic_lesson d-flex align-items-center">
                  <FontAwesomeIcon icon={faGraduationCap} className="link_icon" />
                  <div className="lesson_title ">Curs finalizat</div>
                    </div>
                  </NavLink>
                  </Accordion.Header >
                  </Accordion.Item> 
              </>}
            </div>
          </div>
        </div>

        <Container fluid className="container_lesson px-0">
              {lessonType == 'text' && <StaticLesson lesson={lesson} />}
              {['form', 'quiz', 'final'].includes(lessonType) && <FormLesson lesson={lesson} onFinished={onFinishedLesson}/>}

              {lessonType == 'video' && <VideoLesson lesson={lesson} onFinished={onFinished} />}
              {chapter_id == 'final' && lesson_id == 'final' && course.progress==100 && <FinalCourse course={course} />  }


              {lessonType == 'text' || (lessonType == 'video' && finishedVideo) ?
            
            lesson?.final_lesson ?
            <>
            <button onClick={nextLessonRedirect} className="button_link big_btn btn_border bold mx-auto">
               Finalizează curs
            </button>
            </>
            :
            <button onClick={nextLessonRedirect} className={`button_link big_btn btn_border bold mx-auto`}>
            Lecția următoare <FontAwesomeIcon className="ms-2" icon={faArrowRight} />
            </button>
            : 
            null
          }

          {(['quiz', 'final'].includes(lessonType) && finishedLesson) && lesson?.grade >= 80 ?
            lesson?.final_lesson ?
            <>
            <button onClick={nextLessonRedirect} className="button_link big_btn btn_border bold mx-auto">
               Finalizează curs
            </button>
            </>
            :
            <button onClick={nextLessonRedirect} className={`button_link big_btn btn_border bold mx-auto`}>
            Lecția următoare <FontAwesomeIcon className="ms-2" icon={faArrowRight} />
            </button>
            : null
          }
          {(['form'].includes(lessonType) && finishedLesson) ?
            lesson?.final_lesson ?
            <>
            <button onClick={nextLessonRedirect} className="button_link big_btn btn_border bold mx-auto">
               Finalizează curs
            </button>
            </>
            :
            <button onClick={nextLessonRedirect} className={`button_link big_btn btn_border bold mx-auto`}>
            Lecția următoare <FontAwesomeIcon className="ms-2" icon={faArrowRight} />
            </button>
            : null
          }
        </Container>
      </div>
    </Container>
  );
};

export default CourseDashboard;
