import React, { useState } from 'react'
import { Modal, Row, Col } from "react-bootstrap";
import CourseAPI from '../../api/Course/CourseAPI';
import MessageSuccess from '../MessageSuccess/MessageSuccess';

export default function ModalActivation(props) {
    const { show, onChange } = props;
    const [error, setError] = useState('');
    const [activationCode, setActivationCode] = useState('');
    const [message, setMessage] = useState('');

    const handleClose = () => {
        onChange();
    };
   

    const activateCourse = async () => {

        if (!activationCode) {
            setError("Introduceți codul de activare");
            return;
        }
        
        const response = await CourseAPI.activate(activationCode);
        if (response?.environment?.status?.id == 201 && response?.data?.message) {
            setMessage(response.data.message);
            if (props?.delay) {
                setTimeout(() => {
                    handleClose();
                    window.location.reload();
                }, props.delay);
            }
        }

        setError("Codul introdus nu este valid");
    }

    return (
        <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
        >
            <button
                type="button"
                className="btn-close"
                aria-label="Close"
                onClick={handleClose}
            ></button>
            {message ? <Modal.Body><MessageSuccess message={message} />   </Modal.Body> : <>
                <Modal.Header>
                    <Modal.Title className="bold text-center">
                        Introduceți mai jos codul asociat contului dumneavoastră
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {error && <p className="err_color bold mb-2">{error}</p>}
                    <Row>
                        <Col sm={3}>
                            <label className="col-form-label">Cod activare:</label>
                        </Col>
                        <Col sm={8}>
                            <input
                                className="general_input"
                                type="text"
                                value={activationCode}
                                onChange={(e) => setActivationCode(e.target.value)}
                            />
                        </Col>
                        {}
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <button
                        className="button_link bg_purple w-75 mx-auto"
                        onClick={activateCourse}
                    >
                        Salvează
                    </button>
                </Modal.Footer>
            </>}
        </Modal>
    )
}
