import { faArrowRight, faCreditCard } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect } from "react";
import { Carousel, Col, Container, Row } from "react-bootstrap";
import slide_1 from "../../../images/slide_1.jpg";
import slide_2 from "../../../images/slide_2.jpg";
import slide_3 from "../../../images/slide_3.jpg";
import slide_4 from "../../../images/slide_4.jpg";
import ongoingCourse from "../../../images/ongoing_course.jpg";
import normalCourse from "../../../images/card_course.jpg";
import { CircularProgressbar } from "react-circular-progressbar";
import ProgressCourse from "../../../components/ProgressCourse/ProgressCourse";
import CourseCard from "../../../components/CourseCard/CourseCard";
import GoingCourseCard from "../../../components/CourseCard/GoingCourseCard";
import CourseAPI from "../../../api/Course/CourseAPI";
import Bubbles from "../../../components/Bubbles/Bubbles";
import CoursePreview from "../../../components/CoursePreview/CoursePreview";

function HomeLogged() {
  const [courses, setCourses] = useState([]);

  const fetchCourses = async () => {
    const response = await CourseAPI.getAllCourses();
    if (response?.environment?.status?.id === 200 && response.data) {
      setCourses(response.data);
    }
  };

  useEffect(() => {
    fetchCourses();
  }, []);

  const carouselItmes = [
    {
      id: 1,
      title: "Slide 1",
      alt: "Slide 1",
      src: slide_1,
    },
    {
      id: 2,
      title: "Slide 2",
      alt: "Slide 2",
      src: slide_2,
    },
    {
      id: 3,
      title: "Slide 3",
      alt: "Slide 3",
      src: slide_3,
    },
    {
      id: 4,
      title: "Slide 4",
      alt: "Slide 4",
      src: slide_4,
    },
  ];

  const paidCourses =
    courses?.paid &&
    courses?.paid.map((item) => {
      return (
        <CourseCard course={item} />
      );
    });
  const freeCourses =
    courses?.free &&
    courses?.free.map((item) => {
      return (
        <CourseCard course={item} />
      );
    });
  const ownCourses =
    courses?.own &&
    courses?.own.map((item) => {
      return (
        <GoingCourseCard course={item} />);
    });

  return (
    <Container>
      <div className="normal_page home_courses">
        {
          courses?.last_course && <CoursePreview course={courses?.last_course} />
        }

        {ownCourses &&
          <Row className="ongoing_courses courses_row">

            <h2 className="bold mb-4">Alte cursuri în desfășurare</h2>{" "}
            {ownCourses}

          </Row>
        }
        {
          paidCourses &&
          <Row className={`general_courses ${ownCourses || courses?.last_course ? 'courses_row':''}`}>
          <div className="d-flex align-items-center justify-content-between flex-column flex-sm-row mb-4">
            <h2 className="bold">Cursuri</h2>
            <a href={courses?.link_site} target="_blank" className="button_link bg_orange bold">
              Cumpără cursuri
              <FontAwesomeIcon icon={faCreditCard} className="ms-2" />
            </a>
          </div>
          {paidCourses}
        </Row>
        }
        {freeCourses && 
        <Row className="general_courses free_courses courses_row">
          <h2 className="bold mb-4">
            Cursuri <span>gratuite</span>
          </h2>
          {freeCourses}
        </Row>}

      </div>
    </Container>
  );
}

export default HomeLogged;
