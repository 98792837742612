import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";

import ProtectedRoute from './routes/ProtectedRoute';
import PublicRoute from './routes/PublicRoute';
import Header from "./layouts/Header";
import Footer from "./layouts/Footer";

// Others Routes
import Error from "./pages/Others/Error";

// Public Routes
import Home from './pages/Public/Home/Home';
import Login from './pages/Public/Login/Login';
import Register from "./pages/Public/Register/Register";
import ActivateAccount from "./pages/Public/ActivateAccount/ActivateAccount"

// Protected Routes
import Course from "./pages/Protected/Course/Course";
import Profile from "./pages/Protected/Profile/Profile";
import Video from "./pages/Protected/Video/Video";
import 'bootstrap/dist/css/bootstrap.min.css';
import './css/main.css';
import Dashboard from "./pages/Protected/Dashboard/Dashboard";

import UserProvider from './singletons/UserProvider.js';
import HomeLogged from "./pages/Protected/HomeLogged/HomeLogged";
import ForgotPassword from "./pages/Public/ForgotPassword/ForgotPassword";
import ResetPassword from "./pages/Public/ResetPassword/ResetPassword";
import CourseDashboard from "./pages/Protected/CourseDashboard/CourseDashboard";
import FinalCourse from "./pages/Protected/Course/FinalCourse";
import { useEffect, useRef } from 'react';

function App() {
	const ref = useRef(true);


	useEffect(() => {
	  const firstRender = ref.current;
  
	  if (firstRender) {
		ref.current = false;
	  } else {
	  }
	})
	return (
		<>
			<UserProvider>
				<Router>
					<Header />
					<Routes>

						<Route element={<ProtectedRoute />}>
							<Route path="/course/:id/:chapter_id?/:lesson_id?" element={<Course />} />
							{/* <Route path="/course/:id/final" element={<FinalCourse />} /> */}
							<Route path="/profile/:slug?" element={<Profile />} />
							{/* { localStorage.getItem("user") && ref.current == false && */}
								<Route path="/home" element={<HomeLogged />} />
							{/* } */}
							<Route path="/courseDashboard" element={<CourseDashboard />} />
							<Route path="/video-screen" element={<Video />} />

						</Route>

						<Route element={<PublicRoute />}>
							<Route path="/login" element={<Login />} />
							<Route path="/signup" element={<Register />} />
							<Route path="/forgot-password" element={<ForgotPassword />} />
							<Route path="/reset-password/:code/:email" element={<ResetPassword />} />
							<Route path="/activate/:code/:email" element={<ActivateAccount />} />		
							
							
							{/* <Route path="/" element={<Home/>} /> */}


						</Route>
						<Route path="*" element={<Error />} />
						<Route path="/" element={<Home/>} />
					</Routes>
					<Footer />

				</Router>
			</UserProvider>
		</>
	);
}

export default App;