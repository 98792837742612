import React, { useState } from "react";
import UserAPI from "../../../api/User/UserAPI";
import { Col, Container, Row } from "react-bootstrap";
import parse from "html-react-parser";
import MessageSuccess from "../../../components/MessageSuccess/MessageSuccess"

const Register = () => {
  const [formData, setFormData] = useState({
    lastname: '',
    firstname: '',
    email: '',
    phone: '',
    password: '',
    password_confirm: '',
  });

  const [error, setError] = useState(false);
  const [errorMap, setErrorMap] = useState({});
  const [message, setMessage] = useState('');


  const handleSignup = async (e) => {
    e.preventDefault();
    const response = await UserAPI.register(formData);
   
    if (response?.environment?.status?.id == 201 && response?.data?.message) {
      setMessage(response?.data?.message);
      return;
    }

    setErrorMap(response?.data?.errors);
    setError(response?.data?.message);

  };

  return (
    <Container>
      <div className="normal_page">
        <h1 className="text-center">Înregistrare</h1>
        {message ? <MessageSuccess message={message} /> :
          <form onSubmit={handleSignup} className="general_form col-11 mx-auto">
            {error && <p className="err_color bold mb-4">{error}</p>}
            <Row>
              <Col sm={6}>
                <Row>
                  <Col md={3}>
                    <label className="col-form-label">Nume*</label>
                  </Col>
                  <Col md={8}>
                    <input
                      className={"general_input" + (errorMap && errorMap['lastname'] ? " input-error" : "")}
                      type="text"
                      name="lastname"
                      value={formData['lastname']}
                      onChange={(e) => setFormData({ ...formData, lastname: e.target.value })}
                    />
                   {errorMap['lastname'] ? <p className="err_color my-2">{parse(errorMap['lastname'])}</p> : null}
                  </Col>
                </Row>
              </Col>
              <Col sm={6}>
                <Row className="justify-content-end mt-2 mt-sm-0">
                  <Col md={3}>
                    <label className="col-form-label">Prenume*</label>
                  </Col>
                  <Col md={8}>
                    <input
                      className={"general_input" + (errorMap && errorMap['firstname'] ? " input-error" : "")}
                      type="text"
                      name="firstname"
                      value={formData['firstname']}
                      onChange={(e) => setFormData({ ...formData, firstname: e.target.value })}
                    />
                    {errorMap['firstname'] ? <p className="err_color my-2">{parse(errorMap['firstname'])}</p> : null}
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col sm={6}>
                <Row>
                  <Col md={3}>
                    <label className="col-form-label">Email*</label>
                  </Col>
                  <Col md={8}>
                    <input
                      className={"general_input" + (errorMap && errorMap['email'] ? " input-error" : "")}
                      type="text"
                      name="email"
                      value={formData['email']}
                      onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                    />
                    {errorMap['email'] ? <p className="err_color my-2">{errorMap['email']}</p> : null}
                  </Col>
                </Row>
              </Col>
              <Col sm={6}>
              
                <Row className="justify-content-end mt-2 mt-sm-0">
                  <Col md={3}>
                    <label className="col-form-label">Telefon*</label>
                  </Col>
                  <Col md={8}>
                    <input
                      className={"general_input" + (errorMap && errorMap['phone'] ? " input-error" : "")}
                      type="text"
                      name="phone"
                      value={formData['phone']}
                      onChange={(e) => setFormData({ ...formData, phone: e.target.value })}
                    />
                    {errorMap['phone']  ? <p className="err_color my-2">{parse(errorMap['phone'])}</p>
                      : null}
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col sm={6}>
                <Row>
                  <Col md={3}>
                    <label className="col-form-label">Parolă*</label>
                  </Col>
                  <Col md={8}>
                    <input
                      className={"general_input" + (errorMap && errorMap['password'] ? " input-error" : "")}
                      type="password"
                      name="password"
                      value={formData['password']}
                      onChange={(e) => setFormData({ ...formData, password: e.target.value })}
                    />
                    {errorMap['password'] ? <p className="err_color my-2">{parse(errorMap['password'])}</p> : null}
                  </Col>
                </Row>
              </Col>
              <Col sm={6}>
             
                <Row className="justify-content-end mt-2 mt-sm-0">
                  <Col md={3}>
                    <label className="col-form-label">Repetă parola*</label>
                  </Col>
                  <Col md={8} >
                    <input
                      className={"general_input" + (errorMap && errorMap['password_confirm'] ? " input-error" : "")}
                      type="password"
                      name="password_confirm"
                      value={formData['password_confirm']}
                      onChange={(e) => setFormData({ ...formData, password_confirm: e.target.value })}
                    />
                    {errorMap['password_confirm'] ?
                    <p className="err_color my-2">{errorMap['password_confirm']}</p> : null}
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row className="align-items-center mt-5 justify-content-center">
              <Col sm={6} md={4} lg={2}> <a href="/login" className="bold frgt_password">Ai deja un cont?</a></Col>
              <Col sm={6} md={6} lg={4}><button type="submit" className="button_link btn_submit  bg_purple submit_btn d-flex justify-content-center bold mt-3 mt-sm-0 w-100">Înregistrare</button></Col>
            </Row>
          </form>
        }
      </div>
    </Container>
  );
};

export default Register;
