import React, { useState, useContext } from "react";
import UserAPI from "../../../api/User/UserAPI"
import { useNavigate } from "react-router-dom";
import UserContext from "../../../singletons/userContext"
import { Col, Container, Row } from "react-bootstrap";

const Login = () => {

  const [isLoggedIn, user, setUser] = useContext(UserContext);

  const navigate = useNavigate();

  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [error, setError] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!email || !password) {
      setError('Completati username si parola!');
    }
    else {

      const body = { email: email, password: password }
      const response = await UserAPI.login(body);

      if (response?.environment?.status?.id == 200 && response?.data?.token) {
        localStorage.setItem('user', JSON.stringify(response.data));
        setUser(response.data);
        navigate('/home');
      }
      else {
        setError(response?.data?.message);
      }
    }

  };

  return (
    <Container>
      <div className="normal_page">
        <Col sm lg={8} xl={6} className="mx-auto">
          <h1 className="text-center">Autentificare</h1>
          <form onSubmit={handleSubmit} className="general_form">
            {error && <p className="err_color bold mb-2">{error}</p>}
            <Row>
              <Col sm={3}><label className="col-form-label">Adresă de email:</label></Col>
              <Col sm={9}><input className={"general_input" + (error && !email ? " input-error" : "")}
                type="text"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              /></Col>
            </Row>
            <Row className="mt-3">
              <Col sm={3}><label className="col-form-label">Parolă:</label></Col>
              <Col sm={9}><input
                type="password" className={"general_input" + (error && !password ? " input-error" : "")}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              /></Col>
            </Row>
            
            <Row className="align-items-center mt-5">
              <Col sm={6}><a href="/forgot-password" className="bold frgt_password">Ai uitat parola?</a></Col>
              <Col sm={6}><button type="submit" className="button_link btn_submit bg_purple submit_btn d-flex justify-content-center bold mt-3 mt-sm-0 w-100">Conectează-te</button></Col>
            </Row>

          </form>
        </Col>
      </div>
    </Container>
  );
};

export default Login;