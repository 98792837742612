import React, {useState,useEffect} from "react";
import { faAngleLeft, faAngleRight, faComments } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import QA_Section from "../Q&A_Section/Q&A_Section";
import { Col, Container } from 'react-bootstrap'

function HeaderLesson(props) {
	const chapter_id = props?.lesson?.chapter?.id;
	const lesson_id = props?.lesson?.id;
	const course_id = props?.lesson?.course;
	const lesson = props?.course?.current_lesson;
	const final_course = props?.lesson?.final_course;

	const [showQA, setShowQA] = useState(false);

	const nextLessonRedirect = async () => {
		if(props.lesson.next_button && !props.lesson?.final_lesson){
		  window.location.href = '/course/' + props.lesson.course + '/' + props.lesson?.next_chapter + '/' + props.lesson?.next_lesson;
		}
		
	  }

	  const previousLessonRedirect = async () => {
		if(props.lesson.previous_button){
		  window.location.href = '/course/' + props.lesson.course + '/' + props.lesson?.previous_chapter + '/' + props.lesson?.previous_lesson;
		}
	  }

	return (
		<Container fluid className="bg_white">
			<Container className='px-0'>
				<div className="header_lesson d-flex align-items-center justify-content-between">
					{
					props?.lesson?.previous_button && 
					<button  className="active" onClick={previousLessonRedirect}>
						<FontAwesomeIcon icon={faAngleLeft} /> Înapoi
					</button>
					}
					{
					!props?.lesson?.previous_button && 
					<button disabled>
						<FontAwesomeIcon icon={faAngleLeft} /> Înapoi 
					</button>
					}
					
					<a className="bold" onClick={(e) => setShowQA(!showQA)} style={{cursor: "pointer"}}>
						<FontAwesomeIcon icon={faComments} className="me-2"/>
						Q&A: {props?.lesson?.nr_questions > 0 ? 'Vezi intrebari din lectie ( ' + props?.lesson?.nr_questions + ' )' : "Adresează-ne întrebări"}
					</a>
					{
					props?.lesson?.next_button && 
					<button className="active" onClick={nextLessonRedirect}>
						Înainte <FontAwesomeIcon icon={faAngleRight} />
					</button>
					}
					{
					!props?.lesson?.next_button && 
					<button disabled>
						Înainte <FontAwesomeIcon icon={faAngleRight} />
					</button>
					}
				</div>
				<div className="d-flex justify-content-center header_qa">
					<Col className='col-12 col-lg-6'>
						{showQA && <QA_Section courseId = {course_id} lessonId = {lesson_id} lesson={props?.lesson?.title} rating={props?.lesson?.chapter?.rating} chapterTitle={props?.lesson?.chapter?.title}/> }
					</Col>
				</div>
			</Container>
		</Container>
	)
}

export default HeaderLesson