import React, { useContext, useEffect, useState } from 'react';
import UserContext from "../../../singletons/userContext.js";
import rights_photo from '../../../images/right_photo_home.jpg';
import bub1 from '../../../images/bubble_1.png';
import bub2 from '../../../images/bubble_2.png';
import bub3 from '../../../images/bubble_3.png';
import bub4 from '../../../images/bubble_4.png';
import { Col, Container, Row } from 'react-bootstrap';
import { faVideo, faLightbulb, faFilePdf, faCreditCard } from '@fortawesome/free-solid-svg-icons'
import StatsCard from '../../../components/StatsCard/StatsCard';
import GeneralAPI from '../../../api/General/GeneralAPI';
import Bubbles from '../../../components/Bubbles/Bubbles';


function Home() {

  const iconMap = {
    pdf: faFilePdf,
    free: faCreditCard,
    exam: faLightbulb,
    video: faVideo,
  };
  const [isLoggedIn, user, setUser] = useContext(UserContext);

  const [general, setGeneral] = useState({});

  const fetchHomeDetails = async () => {
    const response = await GeneralAPI.home();
    if (response?.environment?.status?.id == 200) {
      setGeneral(response.data);
    }
  }

  useEffect(() => {
    fetchHomeDetails();
  }, [])

  const statistics = general?.items && general.items.map((value) => {
    return <StatsCard icon={iconMap[value.type]} value={value.value + '+'} description={value.description} />
  })

  return (
    <Container fluid className='position-relative homepage px-0'>
      <Container className='body_content position-relative' style={{ "z-index": "100" }}>
        <Row>
          <Col md={8} lg={7} xl={5}>
            <div className="home_ls">
              <h1 className="home_title">{general?.title} <span className="ev_info">Ștefan Toma</span>
              </h1>
              <div className="short_desc my-4 my-sm-5">
                <p>{general?.content}</p>
              </div>
              <a href={isLoggedIn ? "/home" : "/login"} className="button_link big_btn bg_purple  bold">Începe acum</a>
            </div>
          </Col>
        </Row>
        <div className="d-flex flex-wrap row_info_cards">
          {statistics}
        </div>
      </Container>
      <Col md={5} lg={6} xl={8} className='mask_wrap d-flex justify-content-end'>
        <div className="mask_img mx-2 mx-sm-5 mx-md-0">
          <img src={rights_photo} alt="Right side photo" />
        </div>
      </Col>
      <Bubbles />
    </Container>
  )
}

export default Home