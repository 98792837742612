import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

function StatsCard(props) {
  return (
	<div className="info_card shadow_black m-1 m-sm-2">
		<div className="d-flex align-items-center">
			<div className="icon_wrap d-flex bg_purple_light">
				<FontAwesomeIcon icon={props.icon} />
			</div>
			<div className="rside stats ms-2 ms-sm-4 text-center">
				<p className="ev_info bold">{props.value}</p>
				<p>{props.description}</p>
			</div>
		</div>
	</div>
  )
}

export default StatsCard