import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Col } from "react-bootstrap";

function CourseCard(props) {

  const course = props.course;

  return (
    <Col sm={6} lg={4} className="my-3">
      <div className={`course_wrap ${course?.is_free == 1 ? "position-relative" : ""}`}>
        {
          course?.is_free == 1 && <span className="free_tag bold">gratuit</span>}

        <div className="img_wrap">
          <a href={'/course/' + course?.id}>
            <img src={course?.image} className="image" alt="" />
          </a>
        </div>
        <div className="content">
          <h5>
            <a href={'/course/' + course?.id} className="bold">
              {course?.title}
            </a>
          </h5>
          <p>
            {course?.short_description || course?.description}
          </p>
          <a href={'/course/' + course?.id} className="button_link bg_purple bold">
            Vezi mai multe
            <FontAwesomeIcon icon={faArrowRight} className="ms-2" />
          </a>
        </div>
      </div>
    </Col>
  );
}

export default CourseCard;
