import { instance, instanceWithoutToken } from '../config/api';
import { apiCall } from '../config/apiCalls';

class CourseAPI {
    static async uploadBlob(data) {
        return apiCall(instance, 'post', '/upload_blob', data);
    }

    static async details(courseId, chapterId, lessonId) {
        return apiCall(instance, 'get', `/course/${courseId}` + (chapterId ? `/${chapterId}` : '') + (lessonId ? `/${lessonId}` : ''));
    }
    static async details_final(courseId) {
        return apiCall(instance, 'get', `/course_final/${courseId}`);
    }

    static async activate(code) {
        return apiCall(instance, 'post', '/activate_course', { code: code });
    }
    static async activate_free(id) {
        return apiCall(instance, 'post', '/activate_course_free', { id: id });
    }

    static async getAllCourses() {
        return apiCall(instance, 'get', '/courses');
    }

    static async getLastCourse(){
        return apiCall(instance, 'get', '/last_course');
    }

    static async checkFormLesson(data){
        return apiCall(instance, 'post', '/submit_form', data);
    }
    static async downloadFile(){
        // trimite lectia si automat se descarca fisierul
        return apiCall(instance, 'get', '/download_file', );
    }

    static async addQuestion(data){
        return apiCall(instance, 'post', '/add_question', data);
    }

    static async getQuestions($lessonId){
        return apiCall(instance, 'get', '/get_questions/' + $lessonId);
    }

    static async nextLesson(data){
        return apiCall(instance, 'post', '/next_lesson', data);
    }

}

export default CourseAPI;