import axios from 'axios';


const instance = axios.create({
    // baseURL: 'http://localhost/creative-elearning/a/restapi/elearning',
    baseURL: 'https://elearning.steftoma.com/a/restapi/elearning',
});
const instanceWithoutToken = axios.create({
    // baseURL: 'http://localhost/creative-elearning/a/restapi/elearning',
    baseURL: 'https://elearning.steftoma.com/a/restapi/elearning',
});

instance.interceptors.request.use(
    config => {
        const token = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')).token : null;
        if (token) {
            config.headers['Authorization'] = 'Bearer ' + token;
        }
        return config;
    },
    error => {
        Promise.reject(error)
    }
);

instance.interceptors.response.use((response) => {

    if([401,404].includes(response?.data?.environment?.status.id)){
        localStorage.removeItem('user');
        window.location.href = '/login';
    }
    return response
}, function (error) {

    if (error.response?.status === 401) {
        // Mesaj eroare / nelogat / redirect catre login
    } else {
        return Promise.reject(error);
    }
});

export { instance, instanceWithoutToken };