import React, { useState, useEffect } from "react";
import HeaderLesson from "../HeaderLesson/HeaderLesson";
import QA_Section from "../Q&A_Section/Q&A_Section";
import { Col, Container } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faComments,
	faFileArrowDown,
	faVideo,
} from "@fortawesome/free-solid-svg-icons";
import VideoScreen from "../VideoScreen/VideoScreen";
import VimeoPlayer from "../VideoScreen/VimeoPlayer";
import MessageSuccess from "../MessageSuccess/MessageSuccess";

function VideoLesson(props) {

	const lesson = props.lesson;
	const [acceptVideo, setAcceptVideo] = useState(false);
	const [hasCamera, setHasCamera] = useState(false)
	const [hasMicrophone, setHasMicrophone] = useState(false)

	const [isFinished, setIsFinished] = useState(false)

	const [error, setError] = useState('');

	const [start, setStart] = useState(false)

	const handleClick = () => {

		if (!acceptVideo) {
			setError('Trebuie sa accepti pornirea camerei web!')
			return;
		}
		setStart(!start)
		navigator.mediaDevices.getUserMedia({ audio: true, video: true })
			.then(function (stream) {
				if (stream.getVideoTracks().length > 0 && stream.getAudioTracks().length > 0) {
					setHasCamera(true);
					setHasMicrophone(true);

				}
			})
			.catch(function (error) {
			});
	}

	const YoutubeEmbed = ({ embedId }) => (
		<div className="video-responsive mt-3">
			<iframe
				width="800"
				height="500"
				src={`https://www.youtube.com/embed/${embedId}`}
				allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
				allowFullScreen
				title="Embedded youtube"
			/>
		</div>
	);
	const downloadLesson = (e) => {
		window.location.href = lesson.file_download;
		e.preventDefault();
	  }

	const handleVideoEnd = () => {
		setIsFinished(true);
		props.onFinished();
	}
	const [showQA, setShowQA] = useState(false);

	return (
		<div className="lesson_page">
			<HeaderLesson lesson={lesson} />
			{isFinished ? <MessageSuccess message={'Ai terminat lecția!'}/>:''}
			<Container>
				<div className="normal_page">
					<div className="subtitle_section">
						<h2 className="bold text-center mb-3">
							Modulul {lesson?.chapter?.rating}: {lesson?.chapter?.title}
						</h2>
						<h3 className="text-center">
							L3. Prezentare video despre focalizare
						</h3>
					</div>
					<div className="lesson_body_content">
						<div className="regular_content">

							<div className="my-5">
								{lesson.status == 'completed' || (!isFinished && hasCamera && hasMicrophone && acceptVideo) ?
									<>
										{start && lesson.status != 'completed' && <VideoScreen isFinished={isFinished}  user2lesson_id={lesson?.user2lesson_id} videoId={lesson?.video} onEnd={handleVideoEnd} />}
										{!isFinished && lesson.status == 'completed' && <VimeoPlayer videoId={lesson?.video} onEnd={handleVideoEnd} />}
									</>
									:
									<>
										{!isFinished ?  
											<>
												{error && <p className="err_color bold mb-2">{error}</p>}
												<p>
													Pentru parcurgere lecție este necesară pornirea camerei web,
													apoi permiterea înregistrării de către platformă
												</p>
												<label className="d-flex align-items-center">
													<input type="checkbox" name="accept-video" value="yes" onChange={() => setAcceptVideo(!acceptVideo)} />{" "}
													<span>Sunt de acord cu pornirea camerei web</span>
												</label>
												<button className="button_link bg_purple mt-3" onClick={handleClick}>
													<FontAwesomeIcon icon={faVideo} className="me-2" />
													Înregistrează
												</button>
												{lesson?.youtube_trailer && <YoutubeEmbed embedId={lesson?.youtube_trailer} />}
											</>
											:''
										}
									</>


								}
							</div>
							<div className="d-flex flex-column flex-lg-row justify-content-between align-items-center down_line_lesson mt-4 mt-lg-5">
								{lesson?.file_download && <button onClick={downloadLesson} className="button_link bg_orange me-2">
								<FontAwesomeIcon icon={faFileArrowDown} className="me-2" />
								Descarcă PDF cu ce ai învățat azi
								</button>}
								<a className="bold mt-2 mt-md-4 mt-lg-0 ms-lg-auto" onClick={(e) => setShowQA(!showQA)} style={{cursor: "pointer"}}>
									<FontAwesomeIcon icon={faComments} className="me-2" />
									Q&A: Adresează-ne întrebări
								</a>
							</div>
							<Col className='col-12 col-lg-6 ms-auto mt-3' >
                				{showQA && <QA_Section courseId = {lesson?.course} lessonId = {lesson?.id} lesson={lesson?.title} rating={lesson?.chapter?.rating} chapterTitle={lesson?.chapter?.title}/> }
             				</Col>
						</div>
					</div>
				</div>
			</Container>
		</div>
	);
}

export default VideoLesson;
