// import React from 'react'
// import { Confetti } from 'react-confetti';

// function FinalCourse() {
//   return (
// 	<div>
// 	<Confetti />
// 	</div>
//   )
// }

// export default FinalCourse

import {useEffect, useState} from 'react';
import ConfettiGenerator from 'confetti-js';
import { Col, Container, Row } from 'react-bootstrap';
import finalImg from '../../../images/final_course_img.svg';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
 
const ConfettiCanvas = () => {
  useEffect(() => {
    const confettiSettings = {
      target: 'confetti-canvas'
    };
    const confetti = new ConfettiGenerator(confettiSettings);
    confetti.render();
 
    return () => confetti.clear();
  }, []);
  return <canvas id='confetti-canvas' />;
};
 
export default  function FinalCourse(props) {
  const [isConfetti] = useState(true);
  const course = props?.course;
  return (
    <Container fluid className='overflow-hidden final_course px-0'>
      {isConfetti && <ConfettiCanvas />}
		<div>
		<div className='normal_page'>
			<Container className='container_finalc'>
        <Row>
          <Col lg={6}>
          <div className='home_ls'>
              <h1 className='home_ls_title bold ev_info'>{course?.final_title}</h1>
              <div className='short_desc my-4 my-sm-5'>
                <p>
                {course?.final_text}
                </p>
                <p className='mt-3 zoom_desc'>
                <span className='me-2'>{course?.final_desc_link}</span> 
                 <a href={course?.final_link_zoom}>
                {course?.final_link_zoom}
                </a>
                </p>
               
              </div>
              <a className='button_link big_btn bg_purple bold' href={'/home'}>
              <FontAwesomeIcon icon={faArrowLeft} className='me-2'/>Înapoi la cursuri
              </a>
            </div>
          </Col>
          <Col lg={6} className='mt-5 mt-lg-0'>
           <img src={finalImg}></img>
          </Col>
        </Row>
      </Container>
			
		</div>
		</div>
	
    </Container>
  );
};

// export default FinalCourse