import React from 'react'
import bub1 from "../../images/bubble_1.png";
import bub2 from "../../images/bubble_2.png";
import bub3 from "../../images/bubble_3.png";
import bub4 from "../../images/bubble_4.png";

function Bubbles() {
  return (
	<div>
	<img
	src={bub1}
	className="position-absolute bub bub_pos_1"
	alt=""
  />
  <img
	src={bub2}
	className="position-absolute bub bub_pos_2"
	alt=""
  />
  <img
	src={bub3}
	className="position-absolute bub bub_pos_3"
	alt=""
  />
  <img
	src={bub4}
	className="position-absolute bub bub_pos_4"
	alt=""
  />
  <img
	src={bub3}
	className="position-absolute bub bub_pos_5"
	alt=""
  />
  <img
	src={bub1}
	className="position-absolute bub bub_pos_6"
	alt=""
  />
  </div>
  )
}

export default Bubbles