import React, { useEffect, useState } from 'react'
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

export default function ProgressCourse(props) {
  const [percentage, setPercentage] = useState(0);
  useEffect(() => {
    setTimeout(() => {
      if (percentage < props?.percentage) {
        setPercentage(percentage + 1);
      }
    }, 50);
  }, [percentage]);
  return (
    <div>
      <div style={{ textAlign: "center" }}>
        <div>
          <CircularProgressbar value={percentage} text={`${percentage}%`} />
        </div>
      </div>
    </div>
  )
}

