import { faComment, faQuestion } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import CourseAPI from '../../api/Course/CourseAPI'
import MessageSuccess from '../MessageSuccess/MessageSuccess'

function QA_Section(props) {

	const [questions, setQuestions] = useState([])
	const [question, setQuestion] = useState('')
	const [error, setError] = useState('');
	const [message, setMessage] = useState('');

	const getQuestions = async () => {

		const response = await CourseAPI.getQuestions(props.lessonId);
		if (response?.environment?.status?.id == 200) {
			setQuestions(response.data.questions);
		}
	}

	useEffect(() => {
		getQuestions();
	}, []);

	const sendQuestion = async (e) => {
		e.preventDefault();

		if (question == '') {
			setError('Vă rugăm să introduceți o întrebare.');
			return;
		}
		const body = {
			question: question,
			lesson_id: props.lessonId,
			course_id: props.courseId
		}

		const response = await CourseAPI.addQuestion(body);
		if (response?.environment?.status?.id == 201) {
			setMessage(response.data?.message);

		}
		console.log(response.data);
		setError('A apărut o eroare. Vă rugăm să încercați din nou mai târziu.');
	}



	return (
		<div className=" qa_form exercise wrapper">
			{message ? <MessageSuccess message={message} /> :
				<form onSubmit={sendQuestion}>
					<div className="question bold mb-3 text-center">
						Adresează-ne o întrebare
					</div>
					<div className="answers">
						<textarea className="general_input" rows="2" onChange={(e) => setQuestion(e.target.value)}></textarea>
					</div>
					{error && <p className="err_color bold mb-2">{error}</p>}
					<button className="mt-3 bold button_link  bg_orange mx-auto d-flex" >
						Trimite întrebarea
					</button>
				</form>
			}
			<div className="section_questions mt-4">
				{questions && questions.length > 0 && <div className="mb-3 bold text-center">Alte întrebări din lecția <span className='bold color_purple'>{props.lesson}, Modulul {props.rating}: {props.chapterTitle}</span></div>}
				
				<div>
					{questions && questions.map((question, index) => {
						return (
							<div className="q_box my-2" key={index}>
								<div className="qa_question"><span className="bold me-2"><FontAwesomeIcon icon={faQuestion} /></span>{question.question}</div>
								<div className="qa_answer mt-2"><span className="bold color_yellow me-2"><FontAwesomeIcon icon={faComment} /></span>{question.answer}</div>
								<div></div>
							</div>
						)
					})}
				</div>
			</div>
		</div>
	)
}

export default QA_Section