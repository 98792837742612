import React, { useContext, useState, useEffect } from "react";
import UserContext from "../singletons/userContext.js";

import "../css/main.css";
import logo from "../images/logo.png";
import { Container, Button, Modal, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHouse, faUser } from "@fortawesome/free-solid-svg-icons";
import ModalActivation from "../components/ModalActivation/ModalActivation.js";
function Header() {
  const [isLoggedIn, user, setUser] = useContext(UserContext);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleModal = () => {
    setShow(!show);
  };


  return (
    <nav className="nav_pos">
      <Container fluid className="header_nav bg_purple px-0">
        <Container>
          <div className="d-flex row_logo_links justify-content-between">
            <a href={isLoggedIn ? '/home' : '/'}>
              <img className="logo" src={logo} alt="Logo" />
            </a>
            <div className="links_rs_nav d-flex align-items-center">
              {isLoggedIn ? (
                <>

                <div className="nav_icons_wrap">
                  <a href="/home" className="icon_link">
                    <FontAwesomeIcon icon={faHouse} />
                  </a>
                  <a href="/profile" className="icon_link ms-2">
                    <FontAwesomeIcon icon={faUser} />
                  </a>
                  </div>

                  <button
                    className="button_link bg_orange bold ms-4"
                    onClick={handleShow}
                  >
                    Adaugă cod
                  </button>
                  {show && <ModalActivation onChange={handleModal} show ={show} />}

                </>
              ) : (
                <>
                  <a href="/signup" className="simple_link bold">
                    Înregistrare
                  </a>
                  <a href="/login" className="button_link bg_orange bold ms-4">
                    Autentificare
                  </a>
                </>
              )}
            </div>
          </div>
        </Container>
      </Container>
    </nav>
  );
}

export default Header;
