import React, {useState} from "react";
import { Carousel, Col, Container } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleLeft,
  faAngleRight,
  faComment,
  faComments,
  faFileArrowDown,
  faQuestion,
} from "@fortawesome/free-solid-svg-icons";
import HeaderLesson from "../HeaderLesson/HeaderLesson";
import QA_Section from "../Q&A_Section/Q&A_Section";
import MessageSuccess from "../MessageSuccess/MessageSuccess";

function StaticLesson(props) {
  const lesson = props.lesson;

  const [showQA, setShowQA] = useState(false);


  const downloadLesson = (e) => {
    window.location.href = lesson.file_download;
    e.preventDefault();
  }

  return (
    <div className="lesson_page">
      <HeaderLesson lesson={lesson}/>
      <Container>
      {lesson && lesson.status == 'completed' && <><MessageSuccess message={'Lecție finalizată'}/>   </>}
        <div className="normal_page">
          <div className="subtitle_section">
            <h2 className="bold text-center mb-3">
              Modulul {lesson?.chapter?.rating}: {lesson?.chapter?.title}
            </h2>
            <h3 className="text-center">
              {lesson?.rating && "L" + lesson.rating + ". "} {lesson?.title + 'sdasdad'}
            </h3>
          </div>
          <div className="lesson_body_content">
            <div className="regular_content">
              <p>
                {" "}
                <div dangerouslySetInnerHTML={{ __html: lesson?.content }} />
              </p>
              <div className="d-flex flex-column flex-lg-row justify-content-between align-items-center down_line_lesson mt-4 mt-lg-5">
                {lesson?.file_download && <button onClick={downloadLesson} className="button_link bg_orange me-2">
                  <FontAwesomeIcon icon={faFileArrowDown} className="me-2" />
                  Descarcă PDF cu ce ai învățat azi
                </button>}

                <a  className="bold mt-2 mt-md-4 mt-lg-0 ms-lg-auto" onClick={(e) => setShowQA(!showQA)} style={{cursor: "pointer"}}>
                  <FontAwesomeIcon icon={faComments} className="me-2" />
                  Q&A: Adresează-ne întrebări
                </a>
              </div>
              <Col className='col-12 col-lg-6 ms-auto mt-3' >
                {showQA && <QA_Section courseId = {lesson?.course} lessonId = {lesson?.id} lesson={lesson?.title} rating={lesson?.chapter?.rating} chapterTitle={lesson?.chapter?.title}/> }
              </Col>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}

export default StaticLesson;
