import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';

function MessageSuccess(props) {
  return (
    <div className="alert message msg_succes d-flex align-items-center mt-4 bg_green" role="alert">
      <FontAwesomeIcon icon={faCheckCircle} className="me-2" size="2x" />
      <div>
        {props?.message}
      </div>
    </div>
  )
}

export default MessageSuccess