import React from 'react'
import { Col } from 'react-bootstrap'
import ProgressCourse from '../ProgressCourse/ProgressCourse'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'


export default function GoingCourseCard(props) {
	const course = props?.course;
	return (
		<Col sm={6} lg={4} className="my-3">
			<div className="course_wrap">
				<ProgressCourse percentage={course?.progress} />
				<img src={course?.image} className="image" alt="" />
				<div className="middle d-flex justify-content-center align-items-center">
					<div className="content">
						<h5 className="bold mb-4">{course?.title}</h5>
						<p className='mb-4'> {course?.short_description || course?.description}</p>
						<a className="button_link bg_orange bold" href={'/course/' + course?.id}>
							Continuă curs
							<FontAwesomeIcon icon={faArrowRight} className="ms-2" />
						</a>
					</div>
				</div>
			</div>
		</Col>
	)
}
