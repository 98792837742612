import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from "react-router-dom";
import CourseAPI from './../../../api/Course/CourseAPI';
import CourseDashboard from '../CourseDashboard/CourseDashboard';
import CoursePreview from '../../../components/CoursePreview/CoursePreview';
import ClipLoader from "react-spinners/ClipLoader";
import FinalCourse from './FinalCourse';

function Course() {

  const { id, chapter_id, lesson_id} = useParams();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    },500)
  },[])

  const navigate = useNavigate();
  const [course, setCourse] = useState();
  const fetchCourseDetails = async () => {
  const response = await CourseAPI.details(id, chapter_id, lesson_id);

    if (response?.environment?.status?.id == 200) {
      setCourse(response.data);
    }

    if(chapter_id == 'final' && lesson_id == 'final'){
      const response = await CourseAPI.details_final(id);
      if (response?.environment?.status?.id == 200) {
        setCourse(response.data);
      }
    }

    
  }

  useEffect(() => {
    fetchCourseDetails();
  }, [id, chapter_id, lesson_id]);

  return (
   
  <div className='course_dash'>
      {course && chapter_id && lesson_id && course?.is_purchased ? 
      loading? <div className='spinner_wrapper'><ClipLoader color={'#6d64b1'} loading={loading}  size={150} aria-label="Loading Spinner" data-testid="loader"/></div> :
     <CourseDashboard course={course} lesson_id = {lesson_id} chapter_id = {chapter_id}/> : ''}
      

      {!chapter_id && !lesson_id ? 
      
      loading? <div className='spinner_wrapper'><ClipLoader color={'#6d64b1'} loading={loading}  size={150} aria-label="Loading Spinner" data-testid="loader"/></div> :
      <div className="normal_page home_courses"><CoursePreview course={course} showStats={true}/></div>:''}

      </div>
  )
}

export default Course