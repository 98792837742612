import { faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Col, Row } from "react-bootstrap";

export default function UserData(props) {
  const user = props?.user;
  return (
    <div className="normal_page">
      <h1 className="text-center bold mb-3">Datele tale</h1>
      <div className="user_data">
        <Row>
          <Col sm={6}>
            <Row>
              <Col xs={5} sm={12} lg={4}>
                <p className="bold">Nume:</p>
              </Col>
              <Col xs={7} sm={12} lg={8}>
                <p className="color_purple">{user?.lastname || '-'}</p>
              </Col>
            </Row>
          </Col>
          <Col sm={6}>
            <Row>
              <Col xs={5} sm={12} lg={4}>
                <p className="bold">Prenume:</p>
              </Col>
              <Col xs={7} sm={12} lg={8}>
                <p className="color_purple">{user?.firstname || '-'}</p>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="mt-2">
          <Col sm={6}>
            <Row>
              <Col xs={5} sm={12} lg={4}>
                <p className="bold">
                  <FontAwesomeIcon icon={faEnvelope} className="me-2" />
                  Email:
                </p>
              </Col>
              <Col xs={7} sm={12} lg={8}>
                <p className="color_purple">{user?.email || '-'}</p>
              </Col>
            </Row>
          </Col>
          <Col sm={6}>
            <Row>
              <Col xs={5} sm={12} lg={4}>
                <p className="bold">
                  <FontAwesomeIcon icon={faPhone} className="me-2" />
                  Telefon:
                </p>
              </Col>
              <Col xs={7} sm={12} lg={8}>
                <p className="color_purple">{user?.phone || '-'}</p>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </div>
  );
}


