
import React, { useContext } from "react";
import UserContext from "../singletons/userContext.js";
import logo from "../images/logo.png";
import { Container } from "react-bootstrap";
import instagram from '../images/instagram_logo.png';
import facebook from '../images/facebook_logo.png';

function Footer() {
  const [isLoggedIn, user, setUser] = useContext(UserContext);
  return (
    <Container fluid className="footer px-0">
      <div className="footer_container bg_purple">
        <div className="container">
          <div className="d-flex justify-content-between">
            <a href={isLoggedIn ? '/home' : '/'}>
              <img className="logo" src={logo} alt="" />
            </a>
            <div className="rs_links d-flex align-items-center">
              <span className="simple_link bold me-3">
                CONTACT
              </span>
              <a href="https://www.facebook.com/stef.toma.photo?locale=ro_RO" className="me-2">
                <img style={{width:"24px"}} src={facebook}/>
              </a>
              <a href="https://www.instagram.com/stef.toma/">
                <img style={{width:"24px",marginRight:"10px"}} src={instagram}/>
              </a>
            </div>
          </div>
          <p className="text-center mt-3 mb-0">© Ștefan Toma 2023</p>
        </div>
      </div>
    </Container>
  );
}

export default Footer;
