import React, { useState } from "react";
import { Carousel, Container, Row, Col } from "react-bootstrap";
import StatsCard from "../StatsCard/StatsCard";
import {
  faBook,
  faFilePdf,
  faGraduationCap,
  faLightbulb,
  faPen,
  faVideo,
} from "@fortawesome/free-solid-svg-icons";
import ProgressCourse from "../ProgressCourse/ProgressCourse";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faCreditCard } from "@fortawesome/free-solid-svg-icons";
import ModalActivation from "../ModalActivation/ModalActivation";
import Bubbles from "../Bubbles/Bubbles";
import CourseAPI from "../../api/Course/CourseAPI";

export default function CoursePreview(props) {
  const course = props?.course;

  const [showModal, setShowModal] = useState(false);
  const handleModal = () => {
    setShowModal(!showModal);
  };

  const activateCourse = async () => {
    const response = await CourseAPI.activate_free(course?.id);

    if (response?.environment?.status?.id == 201 && response?.data?.message) {
      window.location.reload();
    }
  };

  const infoCards = [
    {
      icon: faVideo,
      value: course?.videos_count || 0,
      description: "de prezentări video",
    },
    {
      icon: faFilePdf,
      value: course?.pdf_count || 0,
      description: "de materiale PDF",
    },
    {
      icon: faBook,
      value: course?.chapters_count || 0,
      description: "de module",
    },
    {
      icon: faVideo,
      value: course?.chapters_count || 0,
      description: "de lectii",
    },
    {
      icon: faLightbulb,
      value: course?.tests_count || 0,
      description: "teste de evaluare",
    }
  ];

  const statistics = infoCards.map((item) => {
    if (item.value > 0) {
      return (
        <StatsCard
          icon={item.icon}
          value={item.value + "+"}
          description={item.description}
        />
      );
    }
  });
  const imagesLength = course?.images?.length;
  return (
    <Container>
      <Row>
        <Col lg={6}>
          <div className="left_side">
            <div className="carousel_wrap">
              {course?.images && imagesLength == 1 ? (
                course?.images.map((item) => {
                  return <img className="d-block img_preview w-100" src={item.url} />;
                })
              ) : (
                <Carousel>
                  {course?.images.map((item) => {
                    return (
                      <Carousel.Item key={item.id}>
                        <img className="d-block w-100" src={item.url} />
                      </Carousel.Item>
                    );
                  })}
                </Carousel>
              )}
            </div>
          </div>
        </Col>
        <Col lg={6} className="mt-4 mt-sm-5 mt-lg-0">
          <div className="home_ls position-relative">
            <div className="d-flex justify-content-between align-items-center flex-column-reverse flex-sm-row align-items-lg-start">
              <h1 className="home_title bold me-2 me-sm-5 mb-0">{course?.title}</h1>
              {course && course?.is_purchased != 0 && (
                <ProgressCourse percentage={course?.progress} />
              )}
            </div>
            <div className="short_desc my-4 my-sm-5">
              <p>{course?.description}</p>
              <p>{course?.final_text}</p>
            </div>
            {course?.is_purchased == 0 && course?.is_free == 0 && (
              <div className="d-flex justify-content-between flex-wrap">
                <a
                  className="button_link big_btn bg_purple bold"
                  href={course?.link_redirect}
                  target="_blank"
                >
                  Cumpără curs{" "}
                  <FontAwesomeIcon icon={faArrowRight} className="ms-2" />
                </a>
                <button
                  className="button_link big_btn bg_purple bold"
                  onClick={() => setShowModal(true)}
                >
                  Adaugă cod
                </button>
              </div>
            )}

            {course?.is_purchased == 0 && course?.is_free == 1 ? (
              <button
                className="button_link big_btn button_link bg_green bold w-50"
                onClick={activateCourse}
              >
                Adaugă curs grauit
              </button>
            ) : null}
            {course?.is_purchased != 0 ? (
              <a
                href={
                  "/course/" +
                  course?.id +
                  "/" +
                  (course?.is_finished == 1 ? 'final' : course?.last_chapter) +
                  "/" +
                  (course?.is_finished == 1 ? 'final' : course?.last_lesson)
                }
                className="button_link big_btn bg_purple bold"
              >
                {course?.is_finished == 1 ? "Curs finalizat" : <>
                  {course?.progress > 0 ? "Continuă Curs" : "Începe Curs"}
                </>}
              </a>
            ) : null}
            {/* {'asdsfsf'+course?.last_chapter} */}
            <Bubbles />
          </div>
        </Col>
      </Row>
      {showModal && (
        <ModalActivation onChange={handleModal} show={showModal} delay={3000} />
      )}
      {props?.showStats && (
        <div className="regular_content">
          <div className="d-flex flex-wrap row_info_cards my-3 mt-5">
            {statistics}
          </div>
        </div>
      )}
    </Container>
  );
}
