import React, { useState } from "react";
import UserAPI from '../../../api/User/UserAPI.js';
import { Col, Container, Row } from "react-bootstrap";
import MessageSuccess from "../../../components/MessageSuccess/MessageSuccess.js";


const ForgotPassword = () => {

  const [email, setEmail] = useState();
  const [error, setError] = useState(false);
  const [message, setMessage] = useState();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!email) {
      setError('Completati corect adresa de email!');
      return;
    }

    const body = { email: email }
    const response = await UserAPI.forgotPassword(body);

    if (response?.environment?.status?.id == 200 && response?.data?.message) {
      setMessage(response.data.message);
      return;
    }

    setError(response?.data.errors.email);
  };

  return (
    <Container>
      <div className="normal_page">
       
          <h1 className="text-center">Recuperare parola</h1>
          {message ?
            <MessageSuccess message={message} />
            :
            <Col sm lg={8} xl={6} className="mx-auto">
            <form onSubmit={handleSubmit} className="general_form">
              {error && <p className="err_color bold mb-2">{error}</p>}
              <Row>
                <Col sm={3}><label className="col-form-label">Adresă de email:</label></Col>
                <Col sm={9}><input className={"general_input" + (error && !email ? " input-error" : "")}
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                /></Col>
              </Row>
              <Row>
                <Col sm={2}></Col>
                <Col sm={10}><button type="submit" className="button_link btn_submit  bg_purple submit_btn d-flex justify-content-center bold">Trimite email</button></Col>
              </Row>
            </form>
        </Col>}
      </div>
    </Container>
  );
};

export default ForgotPassword;