import React, { useEffect, useState } from 'react'
import { useParams } from "react-router-dom";
import UserAPI from '../../../api/User/UserAPI.js';
import MessageSuccess from '../../../components/MessageSuccess/MessageSuccess';
import { Container } from 'react-bootstrap';

function ActivateAccount() {
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const { code, email } = useParams();

  const checkCode = async () => {

    const body = { email: email, code: code }
    const response = await UserAPI.activateAccount(body);
    if (response?.environment?.status?.id == 200 && response?.data?.message) {
      setSuccess(response?.data?.message);
      return;
    }

    setError('Contul nu a putut fi activat!');
  }

  useEffect(() => {
    checkCode();
  }, []);
  return (
    <>
      <Container className='min_height'>
        {success ? <MessageSuccess message={success} /> : <div>{error}</div>}
      </Container>
    </>
  )
}

export default ActivateAccount