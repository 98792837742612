import React, { useState } from "react";
import UserAPI from '../../../api/User/UserAPI.js';
import { useParams } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import parse from "html-react-parser";
import MessageSuccess from "../../../components/MessageSuccess/MessageSuccess.js";


const ResetPassword = (props) => {
    const { code, email } = useParams();

    const isLogged = props?.isLogged;
    const user = props.user;
    const [oldPassword, setOldPassword] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState(false);
    const [message, setMessage] = useState();

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!password || !confirmPassword) {
            setError('Trebuie sa completați câmpurile!');
        }
        else if(isLogged && !oldPassword) {
            setError('Trebuie să completați parola veche!');
        }
        else if (password != confirmPassword) {
            setError('Parolele trebuie să coincidă!');
        }
        else if (oldPassword == password) {
            setError('Noua parolă trebuie să fie diferită de cea veche!');
        }
        else {
            const body = { email: email, code: code, password: password, password_confirm: confirmPassword, password_old: oldPassword, is_logged: isLogged }
            const response = isLogged ? await UserAPI.changePassword(body) : await UserAPI.resetPassword(body);

            if (response?.environment?.status?.id == 200 && response?.data?.message) {
                setMessage(response.data.message);
                return;
            }

            response?.data?.errors && response?.data?.errors.map((error) => {
                setError(Object.values(error)[0]);

            })
        }
    };

    return (
        <Container>
            <div className="normal_page">
                
                    <h1 className="text-center">Schimbare parola</h1>
                    {message ?
                        <MessageSuccess message={message} />
                        :
                        <Col sm lg={8} xl={6} className="mx-auto">
                            <form onSubmit={handleSubmit} className="general_form">
                                {error && <p className="err_color bold mb-2">{parse(error)}</p>}
                                
                                {
                                    isLogged &&
                                    <Row>
                                        <Col sm={3}><label className="col-form-label">Parola veche:</label></Col>
                                        <Col sm={9}><input className={"general_input" + (error && !oldPassword ? " input-error" : "")}
                                            type="password"
                                            value={oldPassword}
                                            onChange={(e) => setOldPassword(e.target.value)}
                                        /></Col>
                                    </Row>
                                }
                                <Row className="mt-4">
                                    <Col sm={3}><label className="col-form-label">Parola noua:</label></Col>
                                    <Col sm={9}><input className={"general_input" + (error && !password ? " input-error" : "")}
                                        type="password"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                    /></Col>
                                </Row>
                                <Row className="mt-4">
                                    <Col sm={3}><label className="col-form-label">Confirma parola:</label></Col>
                                    <Col sm={9}><input className={"general_input" + (error && !password ? " input-error" : "")}
                                        type="password"
                                        value={confirmPassword}
                                        onChange={(e) => setConfirmPassword(e.target.value)}
                                    /></Col>
                                </Row>
                                <Row>
                                    <Col sm={2}></Col>
                                    <Col sm={10}><button type="submit" className="button_link btn_submit  bg_purple submit_btn d-flex justify-content-center bold">Resetează parola</button></Col>
                                </Row>
                            </form>
                        </Col>
                        }
            </div>
        </Container>
    );
};

export default ResetPassword;