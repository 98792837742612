import React, { useState } from "react";
import HeaderLesson from "../HeaderLesson/HeaderLesson";
import { Col, Container, Row } from "react-bootstrap";
import CourseAPI from "../../api/Course/CourseAPI";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleExclamation, faCircleCheck, faXmark } from '@fortawesome/free-solid-svg-icons'
import MessageSuccess from "../MessageSuccess/MessageSuccess";

export default function FormLesson(props) {
    const lesson = props?.lesson;
    const [selectedAnswers, setSelectedAnswers] = useState({});
    const [requiredAnswers, setRequiredAnswers] = useState([]);
    const [message, setMessage] = useState("");
    const questions = lesson?.questions;
    const [selectedFiles, setSelectedFiles] = useState([]);

   
    const handleFilesChange = (event) => {
        const files = Array.from(event.target.files);
        const question_key = event.target.name;
        const filesWithPreviews = files.map(file => ({
            question_id: question_key,
            file,
            preview: URL.createObjectURL(file)
        }));


        setSelectedFiles(prevImages => prevImages.concat(filesWithPreviews));
    };

    const uploadFiles = () => {

    };

    const deletePhoto = (e, photo_id) => {
        e.preventDefault();
        const newFiles = selectedFiles.filter((file, index) => index !== photo_id);
        setSelectedFiles(newFiles);
    }

    const saveForm = async (e) => {
        e.preventDefault();
        const answers = transformData(selectedAnswers)
        const body = {
            course_id: lesson?.course,
            lesson_id: lesson?.id,
            answers: answers,
            user2lesson_id: lesson?.user2lesson_id
        }
        const formData = new FormData();

        
        for (let i = 0; i < selectedFiles.length; i++) {
            formData.append('images[]', selectedFiles[i].file, selectedFiles[i].file.name);
            formData.append('question_ids[]', selectedFiles[i].question_id);
        }
        formData.append('course_id', lesson?.course);
        formData.append('lesson_id', lesson?.id);
        formData.append('answers', JSON.stringify(answers));
        formData.append('user2lesson_id', lesson?.user2lesson_id);
  
        getFormDataAsArray(formData);
        const response = await CourseAPI.checkFormLesson(formData);
        setRequiredAnswers(response?.data);

        if (response?.data?.message) {
            setMessage(response?.data.message);
            props.onFinished();
        }
    }

    function getFormDataAsArray(formData) {
        const formDataArray = [];
        for (let [key, value] of formData.entries()) {
          formDataArray.push({ key, value });
        }
        return formDataArray;
      }

    function transformData(data) {
        const answersData = {};

        for (const key in data) {
            const [questionId, subQuestionId] = key.replace("question_id_", "").split('_');
            if (subQuestionId) {
                if (!answersData[questionId]) {
                    answersData[questionId] = [];
                }
                answersData[questionId].push(data[key]);
            } else {
                answersData[questionId] = data[key];
            }
        }
        return answersData;
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setSelectedAnswers((prevValues) => ({
            ...prevValues,
            [name]: value
        }));

    };

    const calculateProcent = () => {
        const totalCorrectAnswers = lesson?.correct_answers.length;
        const totalAnswers = questions.length;
        const percentage = (totalCorrectAnswers / totalAnswers) * 100;
    }

    const renderAnswer = (question, unique_id, handleInputChange) => {
        switch (question.type) {
            case 'text':
                return (
                    <input name={unique_id} id={unique_id} className="general_input" type="text" disabled={lesson?.is_submited ? true : false} value={lesson?.is_submited && question?.user_responses[0] ? question?.user_responses[0] : null} onChange={handleInputChange} />
                );
            case 'textarea':
                return (
                    <textarea disabled={lesson?.is_submited ? true : false} value={lesson?.is_submited && question?.user_responses[0] ? question?.user_responses[0] : null} name={unique_id} id={unique_id} className="general_input" rows="5" onChange={handleInputChange} />
                );
            case 'select':
                return (
                    <select disabled={lesson?.is_submited ? true : false} name={unique_id} id={unique_id} className="general_input" onChange={handleInputChange}>
                        {question?.options && question?.options.map((answer, index) => {
                            return (
                                <option {...(question?.user_responses && question?.user_responses.includes(answer.id) ? { selected: true } : {})} key={index} value={answer?.id}>{answer?.title}</option>
                            )
                        })}
                    </select>
                );
            case 'radio':
                return (
                    question?.options && question?.options.map((answer, index) => {
                        return (
                            <label className="d-flex align-items-center" key={index}>
                                <input
                                    name={unique_id}
                                    type='radio'
                                    value={answer.id}
                                    disabled={lesson?.is_submited ? true : false}
                                    onChange={handleInputChange}
                                    {...(question?.user_responses && question?.user_responses.includes(answer.id) ? { checked: true } : {})}
                                /> <span>{answer?.title}</span>
                            </label>
                        )
                    })
                )
            case 'checkbox':
                return (
                    question?.options && question?.options.map((answer, index) => {
                        return (
                            <label className="d-flex align-items-center" key={index}>
                                <input
                                    name={unique_id + '_' + answer.id}
                                    type='checkbox'
                                    value={answer.id}
                                    onChange={handleInputChange}
                                    disabled={lesson?.is_submited ? true : false}
                                    {...(question?.user_responses && question?.user_responses.includes(answer.id) ? { checked: true } : {})}

                                /> <span>{answer?.title}</span>
                            </label>
                        )
                    })
                )
            case 'file':
                return (
                    <>
                        {lesson?.is_submited ? 
                        <>
                             {question?.user_responses.length > 0 &&
                                    <div className={`container-wrap imgs_wrap ${selectedFiles.length >=3 ? 'justify-content-evenly':''}`}>
                                        {question?.user_responses.map((url, index) =>
                                            <div key={index} className="im_wrap">
                                                <img src={url} className="uploadedPhoto"/>
                                            </div>
                                        )}
                                    </div>
                                }
                        </> :
                            <div>
                                <label className="custom-file-upload">
                                <input name={unique_id} type="file" multiple onChange={handleFilesChange} />
                                Încarcă imagini
                                </label>
                                {selectedFiles.length > 0 && (
                                    <div className={`container-wrap imgs_wrap ${selectedFiles.length >= 3 ? 'justify-content-evenly' : ''}`}>
                                        {selectedFiles.map((fileObject, index) => {
                                            if (fileObject.question_id === unique_id) {
                                                return (
                                                    <div key={index} className="im_wrap position-relative">
                                                        <img src={fileObject.preview} alt={fileObject.file.name} className="uploadedPhoto" />
                                                        <button className="position-absolute delete_btn" onClick={(e) => deletePhoto(e, index)}><FontAwesomeIcon icon={faXmark} /></button>
                                                    </div>
                                                );
                                            }
                                            return null; 
                                        })}
                                    </div>
                                )}
                                
                            </div>
                        }
                    </>
                );

            default:
                return null;
        }
    }

    return (
        <div className="lesson_page">
            <HeaderLesson lesson={lesson} />
            <p className="text-center mt-3 color_red bold">
                            {lesson?.is_submited == 1 ?
                                <><MessageSuccess message={'Ai finalizat acest test!'}/>   </>
                                : 'Atenție! Pentru a ajunge la următoarea lecție este necesar să răspunzi corect la cel puțin 80% din întrebări.'}

             </p>
            
            <Container>
                <div className="normal_page">
                {lesson?.grade !== null && lesson?.grade >= 0  && lesson?.type !='form' 
                ? <h4 className="text-center my-2" >Nota obținută: 
                <span 
                className={`bold ${lesson?.grade<50? 'color_red':lesson?.grade>=50 && lesson?.grade<80 ? 'color_yellow':'color_green'}`}
                >{lesson?.grade}  %</span>
                </h4> : null} 
                    {lesson?.status == 'completed' ?
                    lesson?.grade < 80 && lesson.type!='form' ? <p style={{fontSize:"20px"}} className="text-center color_red mb-3">Ai obținut un punctaj mai mic de 80%. Momentan lecția următoare este blocată.</p>
                    : <p style={{fontSize:"20px"}} className="text-center color_green mb-3">Felcitări! Poți trece la lecția următoare.</p>
                    :null}
                    <div className="subtitle_section">
                        <h2 className="bold text-center mb-3">
                            Modulul {lesson?.chapter?.rating}: {lesson?.chapter?.title}
                        </h2>
                        <h3 className="text-center">{lesson?.rating && 'L' + lesson.rating + '. '} {lesson?.title}</h3>
                    </div>
                    <div className="lesson_body_content">
                       
                        {message ? <MessageSuccess message={message} /> :
                            <form className="exercises_form mt-3" onSubmit={saveForm}>
                                {questions && questions.map((question, index) => {
                                    const unique_id = "question_id_" + question?.id;
                                    return (
                                        <div className="exercise my-4" key={index}>
                                            <div className="question bold">
                                                {requiredAnswers && requiredAnswers.includes(question?.id) && <FontAwesomeIcon icon={faCircleExclamation} className='me-2 err_color' />}
                                                {lesson?.correct_answers && lesson?.correct_answers.includes(question?.id) && <FontAwesomeIcon icon={faCircleCheck} className='me-2 success-color' />}
                                                {lesson?.wrong_answers && lesson?.wrong_answers.includes(question?.id) && <FontAwesomeIcon icon={faCircleExclamation} className='me-2 err_color' />}

                                                {index + 1}. {question?.title} {question?.required == 1 && <span className="text-danger">*</span>}
                                                <p>{question?.description}</p>
                                            </div>
                                            <div className={`${question.type!='file' ? 'answers':'w-100'}`}>
                                                {renderAnswer(question, unique_id, handleInputChange)}
                                            </div>

                                            {requiredAnswers && requiredAnswers.includes(question?.id) && <p className="text-danger">Răspunsul este obligatoriu</p>}
                                        </div>
                                    )

                                })}
                                {!lesson?.is_submited && <button className="mt-5 bold button_link bg_purple" onClick={calculateProcent} type="submit">Finalizează testul</button>}
                            </form>
                        }
                    </div>
                </div>
            </Container>
        </div>
    );
}


