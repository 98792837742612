//import logo from './logo.svg';

import React, { useState, useEffect }from "react";
import VideoScreen from "../../../components/VideoScreen/VideoScreen"

export default function Video() {

  const [hasCamera, setHasCamera] = useState(false)
  const [hasMicrophone, setHasMicrophone] = useState(false)
  const [start, setStart] = useState(false)

  // function getLocalStream() {
  //   navigator.mediaDevices
  //     .getUserMedia({ video: false, audio: true })
  //     .then((stream) => {
  //       window.localStream = stream; // A
  //       window.localAudio.srcObject = stream; // B
  //       window.localAudio.autoplay = true; // C
  //     })
  //     .catch((err) => {
  //       console.error(`you got an error: ${err}`);
  //     });
  // }
  //useEffect(getLocalStream, [])

  function changeStart() {
    setStart(!start)
  }

  // function checkPermissions() {
  //   navigator.permissions.query({ name: "camera" }).then((result) => {
  //     if (result.state === "granted") {
  //       setHasCamera(true)
  //     }
  //     // Don't do anything if the permission was denied.
  //   });

  //   navigator.permissions.query({ name: "microphone" }).then((result) => {
  //     if (result.state === "granted") {
  //       setHasMicrophone(true)
  //     }
  //     // Don't do anything if the permission was denied.
  //   });
  // }

  navigator.mediaDevices.getUserMedia({ audio: true, video: true})
   .then(function (stream) {
         if (stream.getVideoTracks().length > 0 && stream.getAudioTracks().length > 0){

             setHasCamera(true)
             setHasMicrophone(true)
         }
   })
  .catch(function (error) { 
   });

  return (
      <div>
        {start?
          <VideoScreen /> :
          ((hasCamera && hasMicrophone) ?
            <button onClick={changeStart}>Start</button> :
            <h4 style={{marginTop: 50 + 'vh', textAlign: 'center'}}>In order to continue, please allow microphone and camera permissions.</h4>
          )
        }
      </div>
  )
}

