import React from "react";
import { Container } from "react-bootstrap";
import Bubbles from "../../components/Bubbles/Bubbles";
import error_photo from "../../images/404.png";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function Error() {
  return (
    <Container className="error_404">
      <div className="normal_page position-relative">
        <div className="content">
          <h2 className="text-center bold">Oops!</h2>
          <h2 className="text-center mt-3 bold">PAGINA NU A FOST GĂSITĂ</h2>
          <img src={error_photo} className="mx-auto d-block" alt="" />
          <div className="d-flex mt-3">
          <a href="/" className="button_link bg_orange bold mx-auto text-center">
          <FontAwesomeIcon icon={faArrowLeft} className="me-2" />Acasă</a>
          </div>
         
        </div>

        <Bubbles />
      </div>
    </Container>
  );
}

export default Error;
