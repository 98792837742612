import { useContext } from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import UserContext from '../singletons/userContext';

function PublicRoute() {

  const [isLoggedIn, user, setUser] = useContext(UserContext);

  return (
    isLoggedIn ? <Navigate to='/' replace /> : <Outlet />
  );
}

export default PublicRoute;