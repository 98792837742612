import { instance, instanceWithoutToken } from '../config/api';
import { apiCall } from '../config/apiCalls';

class UserAPI {
    static async login(data) {
        return apiCall(instanceWithoutToken, 'post', '/login', data);
    }
    static async logout() {
        return apiCall(instance, 'delete', '/logout');
    }
    static async register(data) {
        return apiCall(instanceWithoutToken, 'post', '/signup', data);
    }
    static async forgotPassword(data) {
        return apiCall(instanceWithoutToken, 'post', '/forgot_password', data);
    }
    static async resetPassword(data) {
        return apiCall(instanceWithoutToken, 'post', '/reset_password', data);
    }
    static async changePassword(data) {
        return apiCall(instance, 'post', '/reset_password', data);
    }
    static async activateAccount(data) {
        return apiCall(instanceWithoutToken, 'post', '/activate_account', data);
    }
    
    static async getProfile() {
        return apiCall(instance, 'get', `/profile`);
    }
}

export default UserAPI;