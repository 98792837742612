import React, { useState } from 'react';
import ReactPlayer from 'react-player/vimeo'


const VimeoPlayer = (props) => {
  const [duration, setDuration] = useState(null);
  const [remaining, setRemaining] = useState(null);

  const handleDuration = (duration) => {
    setDuration(duration);
  }

  const handleProgress = (progress) => {
    setRemaining(duration - progress.playedSeconds);
  }

  const handleEnded = () => {
    if(props.onEnd) {
      props.onEnd();
    }
  }

  return (
    <>
    <p style={{textAlign:'center',fontSize:'18px'}}>Au mai ramas: <span style={{fontWeight:'700',color:'var(--primary_color)'}}>{remaining > 0 ? remaining.toFixed(0) + ' secunde' : "Se încarcă..."}</span></p>
    <div className='player-wrapper' style={{ width: '800px', height: '450px', margin:'40px auto 0' }}>
      <ReactPlayer
        url={'https://vimeo.com/' + props?.videoId}
        className='react-player'
        playing={true}
        controls={true}
        width='100%'
        height='100%'
        onDuration={handleDuration}
        onProgress={handleProgress}
        onEnded={handleEnded}
      />
   
    </div>
    </>
  )
}

export default VimeoPlayer;
